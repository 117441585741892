<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="cusman">
        <ul class="tit">
            <li v-for="(i,index) in tit_list" :key="index" :class="i.path==path?'bei':''" @click="dianji_tit(i)">{{i.name}}</li>
        </ul>
        <div class="box" @click="xuanze=false" v-show="tit_list.length==0">
            <div class="box_tit">
                <div class="box_tit_left" @click.stop>
                    <p @click="xuanze_list.length!=0?xuanze=!xuanze:false">{{dangqian_xuanze_name}}<i v-if="xuanze_list.length!=0" :class="xuanze?'el-icon-caret-bottom':'el-icon-caret-left'"></i></p>
                    <ul v-if="xuanze">
                        <li v-for="(i,index) in xuanze_list" :key="index" :style="dangqian_xuanze_name==i?'color:#409eff':''" @click="dianji_xuanze(i)">{{i}}</li>
                    </ul>
                </div>
                <div class="box_tit_right">
                    筛选{{dangqian_xuanze_name}}
                    <el-tooltip class="item" effect="dark" :content="shaixuan_name" placement="top">
                        <p @click="xuanze_zhanshi_jiekou(dangqian_xuanze_name)">{{shaixuan_name}}</p>
                    </el-tooltip>
                </div>
                <!-- <div class="box_con_anniu">
                    <span @click="dianji_yi_fenpei">已分配列表</span>
                    <span v-if="shifou_keshi||zhuguan||zhu_guanli_yuan||quanbu_kejian" @click="dianji_gonghai_chi">公海池列表</span>
                    <span v-if="zhuguan||zhu_guanli_yuan||quanbu_kejian" @click="dianji_lengdong_cang">冷冻仓列表</span>
                </div> -->
            </div>
            <div class="box_con">
                <div v-if="shifou_keshi||zhuguan||zhu_guanli_yuan||quanbu_kejian" class="jiantou" style="z-index=-99;">
                    <img style="z-index=-99;" src="../../assets/xiaoshou/zhi_left.png" alt="">
                    <img style="z-index=-99;" src="../../assets/xiaoshou/zhi_right.png" alt="">
                </div>
                <div v-if="shifou_keshi||zhuguan||zhu_guanli_yuan||quanbu_kejian" id="xiaoshou_kehu_guanli_one" :style="{width: '6rem', height: '4rem',margin:'0.33rem auto'}"></div>
                <div :style="shifou_keshi||zhuguan||zhu_guanli_yuan||quanbu_kejian?'margin-top:-0.9rem;':''" class="pingheng" >
                    <div id="xiaoshou_kehu_guanli_two" :style="{width: '6rem', height: '4rem'}"></div>
                    <div v-if="shifou_keshi||zhuguan||zhu_guanli_yuan||quanbu_kejian" id="xiaoshou_kehu_guanli_three" :style="{width: '6rem', height: '4rem'}"></div>
                </div>
            </div>
        </div>
        <el-dialog title="选择部门" :center="true" :show-close='false' append-to-body :visible.sync="bumen">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="nei_box">
                        <!-- check-strictly -->
                        <el-tree
                            :data="bumen_tree"
                            show-checkbox
                            default-expand-all
                            ref="tree"
                            highlight-current
                            :props="tree_guize"
                            style="background: #E7E8EB"
                            >
                            <!-- @check='fuxuan' -->
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="bumen_sure">确定</p>
                <p class="quxiao" @click="bumen=false">取消</p>
            </div>
        </el-dialog>
        <el-dialog title="选择产品" :center="true" :show-close='false' append-to-body :visible.sync="xuanze_chanpin">
            <div class="tiao_bumen">
                <div class="xuanze">
                    <p class="tit">选择：</p>
                    <div class="nei_box">
                        <!-- check-strictly -->
                        <el-tree
                            :data="chanpin_tree"
                            show-checkbox
                            default-expand-all
                            ref="xiaoshou_chanpin_tree"
                            highlight-current
                            :props="tree_guize"
                            check-strictly
                            style="background: #E7E8EB"
                            >
                            <!-- @check='fuxuan' -->
                        </el-tree>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chanpin_sure">确定</p>
                <p class="quxiao" @click="xuanze_chanpin=false">取消</p>
            </div>
        </el-dialog>
        <el-dialog title="选择人员" :center="true" :show-close='false' append-to-body :visible.sync="shaixuan_ren">
            <div class="tiao_bumen2">
                <div class="xuanze_left">
                    <p class="tit">选择：</p>
                    <div class="xuanze_sousuo">
                        <i class="el-icon-search"></i>
                        <input type="text" placeholder="搜索" v-model="sousuo_text" @keyup='shaixuan_sousuo'>
                        <!--  @keyup="keyu" -->
                    </div>
                    <div class="mianbaoxie">
                        <div v-for="(a,idx) in mianbao_chaosong_list" :key='idx'  @click="mianbao_chaosong_dian(a,idx)">
                            <p :class="mianbao_chaosong_list.length-1==idx?'p1':'p2'">{{a.name}}</p>
                            <i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="quanxuan" v-if="chaosong_qiye_renyuan_list.length!=0">
                        <img @click="dianji_chaosong_quanxuan" :src="renyuan_chaosong_quanxuan?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                        <span>全选</span>
                    </div>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_bumen_list" :key="idx" @click="dianji_chaosong_bumen(a)">
                                    <!-- <img class="img2" @click.stop='chaosong_bumen(a)' :src="a.zhi?require('../assets/okblue.png'):require('../assets/noccc.png')" alt=""> -->
                                    <p style="margin-left:0.16rem;">{{a.dept_name}}</p>
                                </li>
                            </ul>
                            <ul>
                                <li v-for="(a,idx) in chaosong_qiye_renyuan_list" :key="idx" style='justify-content:left;margin-left:-0.07rem;'>
                                    <img class="img2" @click="dianji_chaosong_xuanren(a)" :src="a.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')" alt="">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="xuanze_right">
                    <p class="tit">已选：</p>
                    <div class="nei_box">
                        <div class="liebiao">
                            <ul>
                                <li v-for="(a,idx) in chaosong_renyuan_list_xuan" :key="idx">
                                    <p>{{a.name}}</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div class="foote">
                <p class="queding" @click="chaosong_renyuan_sure">确定</p>
                <p class="quxiao" @click="shaixuan_ren=false;chaosong_renyuan_list_xuan=[]">取消</p>
            </div>
        </el-dialog>
        <div class='beijing' v-if='chanpin' @click="chanpin=false">
            <div class="beijing_box" @click.stop>
                <i class="el-icon-error guanbi_xuanze_chanpin" @click="chanpin=false"></i>
                <div class='beijing_box_tit'>
                    产品分类：
                    <div class="beijing_box_tit_div">
                        <p class="beijing_box_tit_p">{{dangqian_chanpin.label}}</p>
                        <div class="beijing_box_tit_tree">
                            <el-tree :data="qiye_chanpin_leixing_tree" :props="tree_guize" @node-click="xuanze_chanpin_dalei"></el-tree>
                        </div>
                    </div>
                    搜索：
                    <p class="shiyong_sousuo_chanpin">
                        <i class="el-icon-search"></i>
                        <input v-model="sousuo_chanpin_text" @keyup.enter="chanpin_sousuo()" type="text">
                        <i @click="sousuo_chanpin_text='',chanpin_sousuo()" class='el-icon-circle-close'></i>
                    </p>
                    <span class="shiyong_chanpin_queding" @click="dianji_xuanze_chanpin_baocun()">确定</span>
                </div>
                <ul class="mingxi_chanpin_yangshi">
                    <li v-for='(cp,ind) in dangqian_mingxi_kuang_chanpin' :key="cp.prod_code">
                        <i @click="shanchu_yixuan_chanpin(cp,ind)" class="el-icon-error"></i>
                        {{cp.prod_name}}
                    </li>
                </ul>
                <div class='beijing_box_table'>
                    <el-table :cell-style="liebiao_yangshi" height="500" :header-cell-style="biaotou_yangshi" :data="chanpin_liebiao_list"
                        highlight-current-row
                        ref="Tablea"
                        style="width: 100%"
                        @select = "xuanze_chanpina"
                        @select-all = "xuanze_chanpin_quanxuan"
                        >
                        <el-table-column  type="selection" width="42">
                        </el-table-column>
                        <el-table-column  prop="prod_code" label="产品编号">
                        </el-table-column>
                        <el-table-column  prop="prod_name" label="产品">
                        </el-table-column>
                        <el-table-column prop="cat_name"  label="类型">
                        </el-table-column>
                        <el-table-column  prop="spec" label="规格">
                        </el-table-column>
                        <el-table-column  prop="type" label="型号">
                        </el-table-column>
                        <el-table-column  prop="unit" label="单位">
                        </el-table-column>
                        <el-table-column  prop="price_basic" label="初始价">
                        </el-table-column>
                        <!-- <el-table-column  prop="qty" label="数量">
                        </el-table-column> -->
                        <!-- <el-table-column  prop="price_cost" label="成本" width="80">
                        </el-table-column>
                        <el-table-column  prop="price_sale" label="售价" width="80">
                        </el-table-column> -->
                        <el-table-column  prop="disc_rate" label="调价率">
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <router-view />
    </div>
</template>

<script>
import { get_prod_list_like, no_sale_prod_list, query_all_prod_list_of_category, query_ent_prod_cat_list_tree, query_prod_info_list, query_all_module_sale_userid, query_all_module_read_power_list, query_ent_dept_list, query_dept_staff_list, get_staff_list_like, query_prod_tree_sale_rule, query_customer_allot_rule_detail, query_user_info, query_ent_dept_list_aim, customer_manager_main_interface } from '../../api/api'
export default {
  name: 'cusman', // 客户管理
  data () {
    return {
      tit_list: [],
      dangqian: 0,
      xuanze_list: [],
      dangqian_xuanze_name: '',
      xuanze: false,

      xianshi_quanbu: false,
      multipleSelection: [],

      tu1: [
        {
          value: '',
          name: '公海池'
        },
        {
          value: '',
          name: '已分配'
        }
      ], // 总客户
      tu2: [
        {
          value: '',
          name: '系统分配'
        },
        {
          value: '',
          name: '新增'
        },
        {
          value: '',
          name: '指派'
        }
      ], // 已分配
      tu3: [
        {
          value: '',
          name: '未分配'
        },
        {
          value: '',
          name: '退回'
        },
        {
          value: '',
          name: '冷冻仓'
        }
      ], // 公海池
      bumen: false,
      bumen_tree: [],
      tree_guize: {
        children: 'children',
        label: 'label'
      },
      shaixuan_name: '',
      zhu_guanli_yuan: false,
      zhuguan: false,
      shifou_keshi: false,
      xuanze_chanpin: false,
      chanpin_tree: [],
      path: '',
      sousuo_text: '',
      mianbao_chaosong_list: [
        {
          name: '',
          dept_id: '0'
        }
      ],
      renyuan_chaosong_quanxuan: false,
      chaosong_renyuan_list_xuan: [],
      chaosong_qiye_renyuan_list: [],
      chaosong_qiye_bumen_list: [],
      shaixuan_ren: false,
      quanbu_kejian: false,
      wenzi_zhanshi_yincang: false,
      wenzi_zhanshi_yincang3: false,

      // 产品选择修改选择样式    2020、11、11   啊哈哈  光棍节快乐啊QwQ
      chanpin: false,
      dangqian_chanpin: {
        label: '',
        id: ''
      },
      qiye_chanpin_leixing_tree: [], // 产品大类树
      sousuo_chanpin_text: '', // 搜索产品text
      chanpin_xuanze: '',
      chanpin_liebiao_list: [],
      dangqian_mingxi_kuang_chanpin: []
    }
  },
  created () {
    this.jichu()
    this.lujing(this.$route.path)
    this.mianbao_chaosong_list[0].name = JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name
  },
  mounted () {

  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.lujing(val.fullPath)
      },
      // 深度观察监听
      deep: true
    },
    dangqian_xuanze_name () {
      this.shaixuan_name = ''
    },
    shaixuan_ren () {
      if (!this.shaixuan_ren) {
        this.chaosong_renyuan_list_xuan = []
        this.chaosong_qiye_renyuan_list = []
        this.renyuan_chaosong_quanxuan = false
        this.mianbao_chaosong_list = [
          {
            name: JSON.parse(sessionStorage.getItem('pc_mlbb_ent_info')).ent_name,
            dept_id: '0'
          }
        ]
      }
    },
    chanpin () {
      if (!this.chanpin) {
        this.dangqian_chanpin = {
          label: '',
          id: ''
        }
        this.sousuo_chanpin_text = ''
        this.chanpin_xuanze = ''
        this.chanpin_liebiao_list = []
        // this.dangqian_mingxi_kuang_chanpin=[]
      }
    }
  },
  methods: {
    lujing (path) {
      if (path == '/cusman') {
        this.tit_list = []
        this.path = path
        this.jichu()
        this.tuxing_jiekou()
      } else if (path == '/highseas' || path == '/importexport') {
        this.path = '/highseas'
        this.jichu()
      } else {
        this.path = path
        this.jichu()
      }
      //
    },
    // jianting(i){
    //     console.log(i)
    //     if(i==='/cusman'){
    //         this.tit_list=[]
    //         this.tuxing_jiekou()
    //     }else if(i=='/assignedlist'){
    //         this.dangqian='0'
    //         this.biaoti_zhanshi()
    //     }else if(i=='/highseas'||i=='/importexport'){
    //         this.dangqian='1'
    //         this.biaoti_zhanshi()
    //     }else if(i=='/refware'){
    //         this.dangqian='2'
    //         this.biaoti_zhanshi()
    //     }else if(i=='/customerrules'){
    //         this.dangqian='3'
    //         this.biaoti_zhanshi()
    //     }
    //     //
    // },
    biaoti_zhanshi () {
      if (this.path != '/cusman') {
        if (this.zhuguan || this.zhu_guanli_yuan || this.quanbu_kejian) {
          this.tit_list = [
            {
              path: '/assignedlist',
              name: '已分配'
            },
            {
              path: '/highseas',
              name: '公海池'
            },
            {
              path: '/refware',
              name: '冷冻仓'
            },
            {
              path: '/customerrules',
              name: '分配规则'
            }
          ]
          // this.tit_list=['已分配','公海池','冷冻仓','分配规则']
        }
        if (!this.zhuguan && this.shifou_keshi && !this.zhu_guanli_yuan) {
          this.tit_list = [
            {
              path: '/assignedlist',
              name: '已分配'
            },
            {
              path: '/highseas',
              name: '公海池'
            },
            {
              path: '/customerrules',
              name: '分配规则'
            }
          ]
        }
        if (!this.shifou_keshi && !this.zhuguan && !this.zhu_guanli_yuan && !this.quanbu_kejian) {
          this.tit_list = [
            {
              path: '/assignedlist',
              name: '已分配'
            },
            {
              path: '/customerrules',
              name: '分配规则'
            }
          ]
        }
      }
    },
    jichu () {
      query_all_module_read_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.length != 0) {
            this.quanbu_kejian = true
          } else {
            this.quanbu_kejian = false
            this.huoqu_all_mokuai_xiaoshou()
          }
        } else if (res.data.code == 500) {}
      })
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body.staff_level == 1) {
            this.zhu_guanli_yuan = true
          }
          if (res.data.body.staff_level == 2) {
            this.zi_guanli_yuan = true
          }
          if (res.data.body.dept_manager == 1 || res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
            this.xuanze_list = ['部门', '产品', '人员']
            this.dangqian_xuanze_name = '部门'
            this.zhuguan = true
          } else {
            this.xuanze_list = []
            this.dangqian_xuanze_name = '产品'
            this.zhuguan = false
          }
          this.biaoti_zhanshi()
        } else if (res.data.code == 500) {}
      })
      query_customer_allot_rule_detail({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body == null) {
            this.shifou_keshi = false
          } else {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            if (date.flag_visible == 1) { // 1可视，0不可视
              this.shifou_keshi = true
            } else {
              this.shifou_keshi = false
            }
            this.biaoti_zhanshi()
          }
        } else if (res.data.code == 500) {}
      })
    },
    // 获取是否有销售权限
    huoqu_all_mokuai_xiaoshou () {
      query_all_module_sale_userid({
        data: {
          ent_id: this.$ent_id(),
          read_flag: '1'
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          if (date != null) {
            console.log(date)
            let ids = []
            if (date.staff_ids != undefined) {
              ids = date.staff_ids.split(',')
            }
            console.log(ids)

            ids.forEach(item => {
              if (item == this.$jichuxinxi().user_id) {
                this.quanbu_kejian = true
              }
            })
            this.biaoti_zhanshi()
          }
        } else if (res.data.code == 500) {}
      })
    },
    tuxing_jiekou () {
      customer_manager_main_interface({
        data: {
          ent_id: this.$ent_id(),
          query_type: this.dangqian_xuanze_name == '部门' ? '1' : (this.dangqian_xuanze_name == '人员' ? '3' : '2'), // 1部门2产品3成员
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = res.data.body
          this.tu1[0].value = date.total_sea
          this.tu1[1].value = date.total_alloted
          this.tu2[0].value = date.alloted_sys
          this.tu2[1].value = date.alloted_add
          this.tu2[2].value = date.alloted_dispatch
          this.tu3[0].value = date.sea_unalloted
          this.tu3[1].value = date.sea_back
          this.tu3[2].value = date.sea_cold
          const wenzi_zhanshi = []
          this.tu2.forEach(item => {
            if (item.value == 0) {
              wenzi_zhanshi.push('111')
            }
          })
          if (wenzi_zhanshi.length == 2) {
            this.wenzi_zhanshi_yincang = true
          } else {
            this.wenzi_zhanshi_yincang = false
          }
          const wenzi_zhanshi3 = []
          this.tu3.forEach(item => {
            if (item.value == 0) {
              wenzi_zhanshi3.push('111')
            }
          })
          if (wenzi_zhanshi3.length == 2) {
            this.wenzi_zhanshi_yincang3 = true
          } else {
            this.wenzi_zhanshi_yincang3 = false
          }
          if (this.shifou_keshi || this.zhuguan || this.zhu_guanli_yuan || this.quanbu_kejian) {
            this.canver()
            this.canver_3()
          }
          this.canver_2()
        } else if (res.data.code == 500) {} else if (res.data.code == 10300) {
          this.$message({
            message: '企业还没有设置分配规则',
            type: 'warning'
          })
        }
      })
    },
    dianji_tit (i) {
      if (i.path != this.path) {
        this.$router.push(i.path)
      }
      // if(this.$route.path!='/assignedlist'){
      //     this.$router.push('/assignedlist')
      // }else if(this.$route.path!='/highseas'){
      //     this.$router.push('/highseas')
      // }else if(this.$route.path!='/refware'){
      //     this.$router.push('/refware')
      // }else if(this.$route.path!='/customerrules'){
      //     this.$router.push('/customerrules')
      // }
    },
    dianji_xuanze (i) {
      this.dangqian_xuanze_name = i
      this.xuanze = false
    },
    // 第一幅图
    canver () {
      var echarts = require('echarts')
      var myChart = echarts.init(document.getElementById('xiaoshou_kehu_guanli_one'))
      myChart.setOption({
        title: {
          text: '总客户',
          left: 'center',
          textStyle: {
            color: '#1a2533',
            fontSize: '18',
            fontWeight: 'normal'
          }
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            label: {
              // position: 'inner',
              formatter: '{b} \n' + '({c}' + '、' + '{d}%)',
              color: '#1a2533',
              fontSize: 14
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  // 自定义颜色
                  var colorList = [
                    '#f96c6c', '#6abfa7'
                  ]
                  return colorList[params.dataIndex]
                }
              }
            },
            data: this.tu1,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
      function jinru_yifen_pei (param) {
        console.log(param)

        if (param.name == '已分配') {
          // this.$router.push('/')
          window.location.href = '#/assignedlist'
        } else if (param.name == '公海池') {
          window.location.href = '#/highseas'
        }
      }
      myChart.on('click', jinru_yifen_pei)
    },
    // 第二幅图
    canver_2 () {
      var echarts = require('echarts')
      var myChart = echarts.init(document.getElementById('xiaoshou_kehu_guanli_two'))
      myChart.setOption({
        title: {
          text: '已分配',
          left: 'center',
          textStyle: {
            color: '#1a2533',
            fontSize: '18',
            fontWeight: 'normal'
          }
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            label: {
              // position: 'inner',

              color: '#1a2533',
              fontSize: 14,
              // this.wenzi_zhanshi_yincang?params=>{
              //     if(params.value!=0){
              //         return params.name+'\n'+'('+params.value+'、'+params.percent+'%)'
              //     }else {
              //         return ''
              //     }
              //     console.log(params)
              // }:
              formatter: '{b} \n' + '({c}' + '、' + '{d}%)'

            },
            itemStyle: {
              normal: {
                color: function (params) {
                  // 自定义颜色
                  var colorList = [
                    '#6abfa7', '#69fcab', '#b4fed5'
                  ]
                  return colorList[params.dataIndex]
                }
              }
            },
            data: this.tu2,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
      function jinru_yifen_pei (param) {
        window.location.href = '#/assignedlist'
      }
      myChart.on('click', jinru_yifen_pei)
    },
    // 第三幅图
    canver_3 () {
      var echarts = require('echarts')
      var myChart = echarts.init(document.getElementById('xiaoshou_kehu_guanli_three'))
      myChart.setOption({
        title: {
          text: '公海池',
          left: 'center',
          textStyle: {
            color: '#1a2533',
            fontSize: '18',
            fontWeight: 'normal'
          }
        },
        series: [
          {
            name: '访问来源',
            type: 'pie',
            radius: '55%',
            center: ['50%', '50%'],
            label: {
              // position: 'inner',
              // this.wenzi_zhanshi_yincang3?params=>{
              //     if(params.value!=0){
              //         return params.name+'\n'+'('+params.value+'、'+params.percent+'%)'
              //     }else {
              //         return ''
              //     }
              //     console.log(params)
              // }:
              formatter: '{b} \n' + '({c}' + '、' + '{d}%)',
              color: '#1a2533',
              fontSize: 14
            },
            itemStyle: {
              normal: {
                color: function (params) {
                  // 自定义颜色
                  var colorList = [
                    '#f96c6c', '#d35e5e', '#a44949'
                  ]
                  return colorList[params.dataIndex]
                }
              }
            },
            data: this.tu3,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      })
      function jinru_yifen_pei (param) {
        console.log(param)
        if (param.name == '冷冻仓') {
          window.location.href = '#/refware'
        } else {
          window.location.href = '#/highseas'
        }
      }
      myChart.on('click', jinru_yifen_pei)
    },
    // 点击已分配
    dianji_yi_fenpei () {
      if (this.$route.path != 'assignedlist') {
        this.$router.push('/assignedlist')
      }
    },
    // 点击公海池
    dianji_gonghai_chi () {
      if (this.$route.path != 'highseas') {
        this.$router.push('/highseas')
      }
    },
    // 点击冷冻仓
    dianji_lengdong_cang () {
      if (this.$route.path != 'refware') {
        this.$router.push('/refware')
      }
    },
    dianji_fanhui () {
      this.tit_list = []
      this.dangqian = 0
    },
    xuanze_zhanshi_jiekou (i) {
      console.log(i)
      if (i == '部门') {
        this.bumen = true
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhu_guanli_yuan || this.zi_guanli_yuan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.bumen_tree = date
          } else if (res.data.code == 10169) {}
        })
      } else if (i == '人员') {
        // chaosong_qiye_bumen_list
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhu_guanli_yuan || this.zi_guanli_yuan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.shaixuan_ren = true
      } else if (i == '产品') {
        // this.xuanze_chanpin=true
        // query_prod_tree_sale_rule({data:{
        //     ent_id:this.$ent_id(),
        //     user_id:(this.quanbu_kejian||this.zhu_guanli_yuan||this.zi_guanli_yuan)?null:this.$jichuxinxi().user_id
        // }}).then(res=>{
        //     console.log(res)
        //     if(res.data.code==200){
        //         let date=JSON.parse(res.data.body.data)
        //         console.log(date)
        //         this.chanpin_tree=date
        //     }else if(res.data.code==500){}
        // })
        this.chanpin = true
        query_ent_prod_cat_list_tree({
          data: {
            ent_id: this.$ent_id()
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.qiye_chanpin_leixing_tree = date
          } else if (res.data.code == 500) {}
        })
        query_prod_info_list({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            cat_id: '0',
            active: '1'
          }
        }).then(res => {
          if (res.data.code == 10139) {
            const date = JSON.parse(res.data.body.data)
            this.no_xuan_chanpin(date)
            // console.log(this.buneng_xuan_chanpin)
            // var a = date
            // var b = this.buneng_xuan_chanpin
            // let deal = (arr1, arr2, key) => {
            //     let arr3 = []
            //     for (let index = 0; index < arr1.length; index++) {
            //         let item = arr1[index]
            //         if (!arr2.some(v => v[key] === item[key])) {
            //             arr3.push(item)
            //         }
            //     }
            //     return arr3
            // }
            // console.log(deal(a,b,'prod_id'))
            // this.qiye_chanpin_list=deal(a,b,'prod_id')
            // // buneng_xuan_chanpin
          } else if (res.data.code == 10140) {
          }
        })
        // this.dangqian_mingxi_kuang_chanpin=this.dangqian_xuanze_chanpin
      }
    },
    bumen_sure () {
      console.log(this.$func.getSimpleCheckedNodes(this.$refs.tree.store))
      if (this.dangqian_xuanze_name == '部门') {
        const datb = this.$func.getSimpleCheckedNodes(this.$refs.tree.store)
        let dept_id = null
        if (datb.length != 0) {
          dept_id = this.$func.getSimpleCheckedNodes(this.$refs.tree.store).map(item => item.dept_id).join(',')
        }
        customer_manager_main_interface({
          data: {
            ent_id: this.$ent_id(),
            query_type: '1',
            dept_id: dept_id,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          //
          if (res.data.code == 200) {
            this.shaixuan_name = datb.length != 0 ? this.$func.getSimpleCheckedNodes(this.$refs.tree.store).map(item => item.label).join(',') : ''
            const date = res.data.body
            this.tu1[0].value = date.total_sea
            this.tu1[1].value = date.total_alloted
            this.tu2[0].value = date.alloted_sys
            this.tu2[1].value = date.alloted_add
            this.tu2[2].value = date.alloted_dispatch
            this.tu3[0].value = date.sea_unalloted
            this.tu3[1].value = date.sea_back
            this.tu3[2].value = date.sea_cold
            const wenzi_zhanshi = []
            this.tu2.forEach(item => {
              if (item.value == 0) {
                wenzi_zhanshi.push('111')
              }
            })
            if (wenzi_zhanshi.length == 2) {
              this.wenzi_zhanshi_yincang = true
            } else {
              this.wenzi_zhanshi_yincang = false
            }
            const wenzi_zhanshi3 = []
            this.tu3.forEach(item => {
              if (item.value == 0) {
                wenzi_zhanshi3.push('111')
              }
            })
            if (wenzi_zhanshi3.length == 2) {
              this.wenzi_zhanshi_yincang3 = true
            } else {
              this.wenzi_zhanshi_yincang3 = false
            }
            if (this.shifou_keshi || this.zhuguan || this.zhu_guanli_yuan || this.quanbu_kejian) {
              this.canver()
              this.canver_3()
            }
            this.canver_2()
            this.bumen = false
          } else if (res.data.code == 500) {} else if (res.data.code == 10300) {
            this.$message({
              message: '企业还没有设置分配规则',
              type: 'warning'
            })
          }
        })
      }
    },
    chanpin_sure () {
      if (this.dangqian_xuanze_name == '产品') {
        let prod_id = null
        if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
          prod_id = this.dangqian_mingxi_kuang_chanpin.map(item => item.prod_id).join(',')
        }
        console.log(this.dangqian_mingxi_kuang_chanpin)
        console.log(this.dangqian_mingxi_kuang_chanpin.map((item, index) => item.prod_name).join(','))

        customer_manager_main_interface({
          data: {
            ent_id: this.$ent_id(),
            query_type: '2',
            prod_id: prod_id,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.shaixuan_name = this.dangqian_mingxi_kuang_chanpin.map((item, index) => item.prod_name).join(',')
            const date = res.data.body
            this.tu1[0].value = date.total_sea
            this.tu1[1].value = date.total_alloted
            this.tu2[0].value = date.alloted_sys
            this.tu2[1].value = date.alloted_add
            this.tu2[2].value = date.alloted_dispatch
            this.tu3[0].value = date.sea_unalloted
            this.tu3[1].value = date.sea_back
            this.tu3[2].value = date.sea_cold
            const wenzi_zhanshi = []
            this.tu2.forEach(item => {
              if (item.value == 0) {
                wenzi_zhanshi.push('111')
              }
            })
            if (wenzi_zhanshi.length == 2) {
              this.wenzi_zhanshi_yincang = true
            } else {
              this.wenzi_zhanshi_yincang = false
            }
            const wenzi_zhanshi3 = []
            this.tu3.forEach(item => {
              if (item.value == 0) {
                wenzi_zhanshi3.push('111')
              }
            })
            if (wenzi_zhanshi3.length == 2) {
              this.wenzi_zhanshi_yincang3 = true
            } else {
              this.wenzi_zhanshi_yincang3 = false
            }
            if (this.shifou_keshi || this.zhuguan || this.zhu_guanli_yuan || this.quanbu_kejian) {
              this.canver()
              this.canver_3()
            }
            this.canver_2()
            this.chanpin = false
          } else if (res.data.code == 500) {} else if (res.data.code == 10300) {
            this.$message({
              message: '企业还没有设置分配规则',
              type: 'warning'
            })
          }
        })
      }
    },
    shaixuan_sousuo () {
      if (this.sousuo_text.length == 0) {
        this.sousuo_text = null
        this.renyuan_chaosong_quanxuan = false
        this.chaosong_bumen_renyuan_jiekou()
      } else {
        get_staff_list_like({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            staff_name: this.sousuo_text,
            active: '1'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10027) {
            const date = JSON.parse(res.data.body.data)
            date.forEach(item => {
              this.$set(item, 'zhi', false)
              this.$set(item, 'name', item.staff_name)
            })
            this.chaosong_qiye_renyuan_list = date
            this.panduan_youce_zuoce_have()
          } else if (res.data.code == 10028) {
          }
        })
      }
    },
    mianbao_chaosong_dian (i, index) {
      if (this.mianbao_chaosong_list.length - 1 != index && index != 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        this.chaosong_bumen_renyuan_jiekou()
      } else if (index == 0) {
        this.mianbao_chaosong_list.splice(index + 1, this.mianbao_chaosong_list.length)
        query_ent_dept_list_aim({
          data: {
            ent_id: this.$ent_id(),
            user_id: (this.quanbu_kejian || this.zhu_guanli_yuan || this.zi_guanli_yuan) ? null : this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10168) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(item => {
              this.$set(item, 'dept_name', item.label)
            })
            this.chaosong_qiye_bumen_list = date
          } else if (res.data.code == 10169) {}
        })
        this.chaosong_qiye_renyuan_list = []
      }
    },
    dianji_chaosong_quanxuan () {
      this.renyuan_chaosong_quanxuan = !this.renyuan_chaosong_quanxuan
      if (this.renyuan_chaosong_quanxuan) {
        // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
        // this.chaosong_qiye_bumen_list.map(item=>{
        //     item.zhi=true
        //     this.$set(item,'name',item.dept_name)
        //     this.$set(item,'ids',item.dept_id)
        //     this.$set(item,'type',1)
        //     this.chaosong_renyuan_list_xuan.push(item)
        // })
        // }
        // for(let i=0;i<this.chaosong_qiye_renyuan_list.length;i++){
        this.chaosong_qiye_renyuan_list.map(item => {
          item.zhi = true
          this.$set(item, 'name', item.staff_name)
          this.$set(item, 'ids', item.staff_id)
          this.$set(item, 'type', 2)
          this.chaosong_renyuan_list_xuan.push(item)
        })
        // }
      } else {
        this.chaosong_renyuan_list_xuan = []
        // this.chaosong_qiye_bumen_list.map(item=>{
        //     item.zhi=false
        // })
        this.chaosong_qiye_renyuan_list.forEach(item => {
          item.zhi = false
        })
      }
    },
    dianji_chaosong_bumen (i) {
      this.mianbao_chaosong_list.push({ name: i.dept_name, dept_id: i.dept_id })
      this.chaosong_bumen_renyuan_jiekou()
    },
    // 抄送人部门人员列表
    chaosong_bumen_renyuan_jiekou () {
      query_ent_dept_list({
        data: {
          ent_id: this.$ent_id(),
          active: '1',
          parent_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 10168) {
          const date = JSON.parse(res.data.body.data)
          // date.map(item=>{this.$set(item,'zhi',false)})
          console.log(date)
          this.chaosong_qiye_bumen_list = date
          query_dept_staff_list({
            data: {
              ent_id: this.$ent_id(),
              dept_id: this.mianbao_chaosong_list[this.mianbao_chaosong_list.length - 1].dept_id,
              active: '1'
            }
          }).then(ras => {
            if (ras.data.code == 10149) {
              const listdata = JSON.parse(ras.data.body.data)
              listdata.map(item => { this.$set(item, 'zhi', false); this.$set(item, 'name', item.staff_name) })
              console.log(99999, listdata)
              this.chaosong_qiye_renyuan_list = listdata
              this.panduan_youce_zuoce_have()
              this.panduan_chaosong_quanxuan()
            } else if (ras.data.code == 10150) {
            }
          })
        } else if (res.data.code == 10169) {
        }
      })
    },
    // chaosong_bumen(i){
    //     i.zhi=!i.zhi
    //     if(i.zhi){
    //         this.$set(i,'name',i.dept_name)
    //         this.$set(i,'ids',i.dept_id)
    //         this.$set(i,'type',1)
    //         this.chaosong_renyuan_list_xuan.push(i)
    //     }else{
    //         for(let a=0;a<this.chaosong_renyuan_list_xuan.length;a++){
    //             if(this.chaosong_renyuan_list_xuan[a].ids==i.dept_id){
    //                 this.chaosong_renyuan_list_xuan.splice(a,1)
    //             }
    //         }
    //     }
    //     this.panduan_chaosong_quanxuan()
    // },
    chaosong_renyuan_sure (i) {
      if (this.dangqian_xuanze_name == '人员') {
        customer_manager_main_interface({
          data: {
            ent_id: this.$ent_id(),
            query_type: '3',
            staff_id: this.chaosong_renyuan_list_xuan.length != 0 ? this.chaosong_renyuan_list_xuan.map(ite => ite.staff_id).join(',') : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          if (res.data.code == 200) {
            this.shaixuan_name = this.chaosong_renyuan_list_xuan.map(ite => ite.staff_name).join(',')
            const date = res.data.body
            this.tu1[0].value = date.total_sea
            this.tu1[1].value = date.total_alloted
            this.tu2[0].value = date.alloted_sys
            this.tu2[1].value = date.alloted_add
            this.tu2[2].value = date.alloted_dispatch
            this.tu3[0].value = date.sea_unalloted
            this.tu3[1].value = date.sea_back
            this.tu3[2].value = date.sea_cold
            const wenzi_zhanshi = []
            this.tu2.forEach(item => {
              if (item.value == 0) {
                wenzi_zhanshi.push('111')
              }
            })
            if (wenzi_zhanshi.length == 2) {
              this.wenzi_zhanshi_yincang = true
            } else {
              this.wenzi_zhanshi_yincang = false
            }
            const wenzi_zhanshi3 = []
            this.tu3.forEach(item => {
              if (item.value == 0) {
                wenzi_zhanshi3.push('111')
              }
            })
            if (wenzi_zhanshi3.length == 2) {
              this.wenzi_zhanshi_yincang3 = true
            } else {
              this.wenzi_zhanshi_yincang3 = false
            }
            if (this.shifou_keshi || this.zhuguan || this.zhu_guanli_yuan || this.quanbu_kejian) {
              this.canver()
              this.canver_3()
            }
            this.canver_2()
            this.shaixuan_ren = false
          } else if (res.data.code == 500) {} else if (res.data.code == 10300) {
            this.$message({
              message: '企业还没有设置分配规则',
              type: 'warning'
            })
          }
        })
      }
    },
    // 判断抄送是否全选
    panduan_chaosong_quanxuan () {
      const list = []
      // for(let i=0;i<this.chaosong_qiye_bumen_list.length;i++){
      //     if(!this.chaosong_qiye_bumen_list[i].zhi){
      //         list.push('111')
      //     }
      // }
      for (let i = 0; i < this.chaosong_qiye_renyuan_list.length; i++) {
        if (!this.chaosong_qiye_renyuan_list[i].zhi) {
          list.push('111')
        }
      }
      if (list.length == 0) {
        this.renyuan_chaosong_quanxuan = true
      } else {
        this.renyuan_chaosong_quanxuan = false
      }
    },
    // 判断右侧有的左侧是否有
    panduan_youce_zuoce_have () {
      for (let i = 0; i < this.chaosong_renyuan_list_xuan.length; i++) {
        // for(let a=0;a<this.chaosong_qiye_bumen_list.length;a++){
        //     if(this.chaosong_renyuan_list_xuan[i].ids==this.chaosong_qiye_bumen_list[a].dept_id){
        //         this.chaosong_qiye_bumen_list[a].zhi=true
        //     }
        // }
        for (let a = 0; a < this.chaosong_qiye_renyuan_list.length; a++) {
          if (this.chaosong_renyuan_list_xuan[i].ids == this.chaosong_qiye_renyuan_list[a].staff_id) {
            this.chaosong_qiye_renyuan_list[a].zhi = true
          }
        }
      }
    },
    dianji_chaosong_xuanren (i) {
      console.log(i)
      i.zhi = !i.zhi
      if (i.zhi) {
        this.$set(i, 'name', i.staff_name)
        this.$set(i, 'ids', i.staff_id)
        this.$set(i, 'type', 2)
        this.chaosong_renyuan_list_xuan.push(i)
      } else {
        for (let a = 0; a < this.chaosong_renyuan_list_xuan.length; a++) {
          if (this.chaosong_renyuan_list_xuan[a].ids == i.staff_id) {
            this.chaosong_renyuan_list_xuan.splice(a, 1)
          }
        }
      }
      this.panduan_chaosong_quanxuan()
    },

    // 2020、6、10更改产品样式   2020/11/10修改传值
    xuanze_chanpin_dalei (val) {
      console.log(val)
      this.dangqian_chanpin = val
      if (this.dangqian_chanpin.id.length != 0) {
        query_all_prod_list_of_category({
          data: {
            ent_id: this.$ent_id(),
            cat_id: this.dangqian_chanpin.id + ''
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            // let date=JSON.parse(res.data.body.data)
            console.log(this.buneng_xuan_chanpin)
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            this.no_xuan_chanpin(date)
            // var b = this.buneng_xuan_chanpin
            // let deal = (arr1, arr2, key) => {
            //     let arr3 = []
            //     for (let index = 0; index < arr1.length; index++) {
            //         let item = arr1[index]
            //         if (!arr2.some(v => v[key] === item[key])) {
            //             arr3.push(item)
            //         }
            //     }
            //     return arr3
            // }
            // console.log(deal(a,b,'prod_id'))
            // this.chanpin_liebiao_list=deal(a,b,'prod_id')
            // console.log(this.chanpin_liebiao_list)
            // this.$forceUpdate()
            console.log(this.dangqian_mingxi_kuang_chanpin)

            if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
              this.$nextTick(function () {
                this.chanpin_liebiao_list.forEach(item => {
                  this.dangqian_mingxi_kuang_chanpin.forEach(ite => {
                    if (ite.prod_id == item.prod_id) {
                      console.log(item)
                      this.$refs.Tablea.toggleRowSelection(item)
                    }
                  })
                })
              })
            }
            this.$forceUpdate()
          } else if (res.data.code == 500) {}
        })
      }
    },
    chanpin_sousuo () {
      // sousuo_chanpin_text
      if (this.sousuo_chanpin_text.length != 0) {
        get_prod_list_like({
          data: {
            active: '1',
            ent_id: this.$ent_id(),
            prod_name: this.sousuo_chanpin_text.length != 0 ? this.sousuo_chanpin_text : null,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10031) {
            const date = JSON.parse(res.data.body.data)
            this.no_xuan_chanpin(date)
            // for(let i=0;i<date.length;i++){
            //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
            //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
            //             date.splice(i,1)
            //             i=i-1
            //         }
            //     }
            // }
            // this.chanpin_liebiao_list=date
            // console.log(this.chanpin_liebiao_list)
            this.$forceUpdate()
          } else if (res.data.code == 10032) {}
        })
      } else {
        if (this.dangqian_chanpin.id.length != 0) {
          query_all_prod_list_of_category({
            data: {
              ent_id: this.$ent_id(),
              cat_id: this.dangqian_chanpin.id + ''
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.no_xuan_chanpin(date)
              // console.log(date)
              // console.log(this.buneng_xuan_chanpin)
              // for(let i=0;i<date.length;i++){
              //     for(let a=0;a<this.buneng_xuan_chanpin.length;a++){
              //         if(date[i].prod_id==this.buneng_xuan_chanpin[a]){
              //             date.splice(i,1)
              //             i=i-1
              //         }
              //     }
              // }
              // this.chanpin_liebiao_list=date
              // console.log(this.chanpin_liebiao_list)
              // this.$forceUpdate()
            } else if (res.data.code == 500) {}
          })
        } else {
          this.chanpin_liebiao_list = []
        }
      }
    },
    dianji_xuanze_chanpin_baocun () {
      console.log(this.chanpin_xuanze)
      console.log(this.suoxuan_list)

      this.chanpin_sure()
    },
    liebiao_yangshi (row) {
      return 'color:#4C4A4D;font-size:0.1rem;'
    },
    biaotou_yangshi (row) {
      return 'color:#1A2533;'
    },
    dianji_xuanzhong_chanpin (val) {
      this.chanpin_xuanze = val
    },
    no_xuan_chanpin (date) {
      no_sale_prod_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id,
          // type:'staff',
          staff_ids: this.$jichuxinxi().user_id
        // dept_ids:this.zhanshi_name=='部门'?(this.xuanzhong_bumen_name.length!=0?this.xuanzhong_bumen_id:null):null
        }
      }).then(ras => {
        console.log(ras)
        if (ras.data.code == 200) {
          const data = JSON.parse(ras.data.body.data)
          const list = []
          for (let i = 0; i < data.length; i++) {
            list.push({
              prod_id: data[i],
              cat_id: data[i]
            })
          }
          this.buneng_xuan_chanpin = list
          console.log(list)
          console.log(date)

          var a = date
          var b = list
          const deal = (arr1, arr2, key) => {
            const arr3 = []
            for (let index = 0; index < arr1.length; index++) {
              const item = arr1[index]
              if (!arr2.some(v => v[key] === item[key])) {
                arr3.push(item)
              }
            }
            return arr3
          }
          console.log(deal(a, b, 'prod_id'))
          this.chanpin_liebiao_list = deal(a, b, 'prod_id')
          console.log(this.chanpin_liebiao_list)
          this.$forceUpdate()
        } else if (ras.data.code == 500) {}
      })
    },

    shanchu_yixuan_chanpin (i, index) {
      this.chanpin_liebiao_list.forEach(ite => {
        if (ite.prod_id == i.prod_id) {
          this.$refs.Tablea.toggleRowSelection(ite)
        }
      })
      this.dangqian_mingxi_kuang_chanpin.splice(index, 1)
    },
    xuanze_chanpina (rows, row) {
      console.log(rows)
      console.log(row)
      const ses = rows.length && rows.indexOf(row) !== -1 // 判断是否打钩
      if (this.dangqian_mingxi_kuang_chanpin.length != 0) {
        if (ses) {
          this.dangqian_mingxi_kuang_chanpin.push(row)
        } else {
          for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == row.prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
            }
          }
        }
      } else {
        this.dangqian_mingxi_kuang_chanpin = rows
      }
      // return rows.length && rows.indexOf(row) !== -1
    },
    xuanze_chanpin_quanxuan (rows, row) {
      console.log(rows)
      if (rows.length != 0) {
        rows.forEach(item => {
          this.dangqian_mingxi_kuang_chanpin.push(item)
        })
        this.dangqian_mingxi_kuang_chanpin = this.$func.Es5duplicate(this.dangqian_mingxi_kuang_chanpin, 'prod_id')
      } else {
        // chanpin_liebiao_list
        // this.dangqian_mingxi_kuang_chanpin
        for (let i = 0; i < this.dangqian_mingxi_kuang_chanpin.length; i++) {
          // this.chanpin_liebiao_list.forEach(item=>{
          for (let a = 0; a < this.chanpin_liebiao_list.length; a++) {
            if (this.dangqian_mingxi_kuang_chanpin[i].prod_id == this.chanpin_liebiao_list[a].prod_id) {
              this.dangqian_mingxi_kuang_chanpin.splice(i, 1)
              i = i - 1
              break
            }
          }
          // })
        }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../style/salesman.scss';
</style>
